<div ngbDropdown placement="bottom" class="d-inline-block dropdown-container" [id]="dropdownId" tabindex="1">
  <div class="btn-maplecroft-dropdown dropdown-button d-flex" ngbDropdownToggle>
    <p class="quick-search-dropdown-label lh-16">{{ dropdownMessage }}</p>
    <grid-ui-icon class="mc-dropdown-icon quick-search-dropdown-icon" name="chevron-down"></grid-ui-icon>
  </div>
  <div ngbDropdownMenu>
    <div
      #searchInputContainer
      class="search-container"
      *ngIf="searchable"
      class="grid-ui-search-input-container"
      (click)="$event.stopPropagation()"
    >
      <grid-ui-search-input [debounce]="debounce" [value]="hint" [placeholder]="placeholder" (searchTermChange)="reloadWithHint($event)">
      </grid-ui-search-input>
      <ng-content select="[filter]"></ng-content>
    </div>
    <div class="dropdown-menu-body" *ngIf="dropdownData.loadingStatus !== RequestStatus.error">
      <grid-ui-quick-search-dropdown-results
        #results
        *ngIf="dropdownData.loadingStatus === RequestStatus.loaded && dropdownData.content && dropdownData.content!.items.length > 0"
        [results]="dropdownData.content!.items"
        (activated)="setActive($event)"
        [activeIndex]="activeIndex"
        (resultSelected)="selectedResultByClick($event)"
      >
      </grid-ui-quick-search-dropdown-results>
      <div
        class="no-matching-results-container"
        *ngIf="dropdownData.loadingStatus === RequestStatus.loaded && (!dropdownData.content || dropdownData.content!.items.length === 0)"
      >
        <ng-content></ng-content>
      </div>
      <grid-ui-loading *ngIf="dropdownData.loadingStatus === RequestStatus.loading"></grid-ui-loading>
    </div>
    <grid-ui-additional-countries-offer-footer
      *ngIf="dropdownData.content !== null && dropdownData.content !== undefined && dropdownData.content.showEntitlementFooter"
    >
    </grid-ui-additional-countries-offer-footer>
    <div class="mc-search-results-error" *ngIf="dropdownData.loadingStatus === RequestStatus.error && dropdownData.error">
      <grid-ui-error
        [errorCode]="dropdownData.error!.errorCode"
        [errorMessage]="dropdownData.error!.errorMessage"
        [errorPrimaryAction]="dropdownData.error!.errorPrimaryAction"
        [size]="dropdownData.error!.size"
        (primaryActionClick)="reloadWithHint(hint)"
      >
      </grid-ui-error>
    </div>
  </div>
</div>
