import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { QuickSearchDropdownItemType } from '../models';

@Component({
  selector: 'grid-ui-quick-search-dropdown-result',
  templateUrl: './quick-search-dropdown-result.component.html',
  styleUrls: ['./quick-search-dropdown-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchDropdownResultComponent {
  /** Whether or not this result is active */
  @Input() public active = false;
  /** Whether or not this dropdown result is disabled */
  @Input() public disabled = false;
  /** This result index. Passed along activated event when fired */
  @Input() public currentIndex!: number;
  /** Whether or not hover to activate is enabled */
  @Input() public hoverEnabled = true;
  /** Result to be shown */
  @Input() public result!: QuickSearchDropdownItemType;

  /** Fires when the user hovers on a result, passing along its ID */
  @Output() public activated = new EventEmitter<number>();
  /**
   * Fires when the user clicks on a result and emits the Country Risk Index id or
   * Country/Site geo_id of the selected result.
   */
  @Output() public resultClicked = new EventEmitter<QuickSearchDropdownItemType>();

  constructor(public elementRef: ElementRef) {}

  public activate(): void {
    if (this.hoverEnabled) {
      this.activated.emit(this.currentIndex);
    }
  }

  public rowClicked(): void {
    if (this.disabled) {
      return;
    }

    this.resultClicked.emit(this.result);
  }
}
