<ng-container *ngFor="let result of results; let i = index">
  <h4 *ngIf="result.isHeader" class="quick-search-dropdown-results__title">{{ result.name }}</h4>
  <grid-ui-quick-search-dropdown-result
    #searchResult
    class="quick-search-dropdown-results__item"
    *ngIf="!result.isHeader"
    [active]="activeIndex === i"
    [disabled]="result.isDisabled"
    [currentIndex]="i"
    [hoverEnabled]="!isScrolling"
    [result]="result"
    (activated)="activate($event)"
    (resultClicked)="resultClicked($event)"
  >
  </grid-ui-quick-search-dropdown-result>
</ng-container>
