import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { QuickSearchDropdownItemType } from '../models';
import { QuickSearchDropdownResultComponent } from '../quick-search-dropdown-result/quick-search-dropdown-result.component';

@Component({
  selector: 'grid-ui-quick-search-dropdown-results',
  templateUrl: './quick-search-dropdown-results.component.html',
  styleUrls: ['./quick-search-dropdown-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchDropdownResultsComponent {
  /** Indicates which of the results is active. Used to apply
   * the appropriate styles and to fire the resultSelected event */
  @Input() public activeIndex = 0;
  /** Results to be shown */
  @Input() public results: QuickSearchDropdownItemType[] = [];

  /** Fires when the user hovers on a result, passing along its ID.
   * Bubbled up from child component */
  @Output() public activated = new EventEmitter<number>();
  /** Fires when the user clicks on a result and emits the Country Risk Index id or
   * Country/Site geo_id of the selected result.
   * Bubbled up from child component.
   */
  @Output() public resultSelected = new EventEmitter<QuickSearchDropdownItemType>();

  /** QueryList of searchResult Components */
  @ViewChildren('searchResult') searchResults!: QueryList<QuickSearchDropdownResultComponent>;

  public isScrolling = false;

  public activate(index: number): void {
    this.activated.emit(index);
  }

  public resultClicked(item: QuickSearchDropdownItemType): void {
    this.resultSelected.emit(item);
  }

  public scrollToIndex(index: number) {
    if (!this.searchResults || this.searchResults.length === 0) {
      return;
    }

    const result = this.searchResults.toArray()[index].elementRef.nativeElement;

    if (result) {
      this.isScrolling = true;
      result.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      setTimeout(() => (this.isScrolling = false), 300);
    }
  }
}
